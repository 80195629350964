
.meeting-main{
    display:flex;
    flex-direction:row;
    height:100vh;
    margin-top: 20px;
    /* margin:20px;     */
}
.meeting-left-col{
    display:flex;
    flex-direction:column;
    order:1;
    flex-basis: 30vw;
    border-radius:0 50px 0 0;
    background-color:#fff;
    box-shadow: 4px 0px 25px -8px rgba(51,39,39,0.75);
    -webkit-box-shadow: 4px 0px 25px -8px rgba(51,39,39,0.75);
    -moz-box-shadow: 4px 0px 25px -8px rgba(51,39,39,0.75);
}
.meeting-search{
    display:flex;
    margin:40px;
    flex-direction:column;
    justify-content:space-around;
    order:1;    
}
.meeting-search input{
    border:none;
    padding:10px 0px 10px 20px;
    height:25px;
    background-color:rgb(233, 233, 233);
    width:100%;
    border-radius:20px;
    text-decoration: none;
    outline:none;
}
.meeting-list{
    display:flex;
    flex-direction:column;
    order:2;
    margin:40px;
}
.meeting-list-item{
    display:flex;
    flex-direction:row;
    height:75px;
    border-radius:15px;
    cursor:pointer;
    box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}
.list-item-icon{
    display:flex;
    order:1;
    width:20%;
    align-items:center;
    justify-content:center;
    /* border-right:1px solid rgb(146, 190, 146); */
    /* border-radius:0 50px 50px 0; */
}
.the-icon{
    height:30px;
    width:30px;
    color:rgb(123, 178, 123);
}
.list-item-details{
    display:flex;
    flex-direction:column;
    order:2;
    width:80%;
    padding:5px;
}
.list-item-details h2{
    font-size:13px
}
.list-item-details h3{
    font-size:10px;
}
.list-item-details p{
    font-size:10px;
    margin:0px
}
.meeting-right-col{
    display:flex;
    flex-direction:column;
    order:2;
    width: 70vw;   
}
.meeting-header{
    display:flex;
    order:1;
    margin:40px; 
    border-bottom: 1px solid rgb(149, 198, 149);;
}
.meeting-header h2{
    font-family: Poppins;
    font-size: 22px;
    font-weight:300;
}
.meeting-main-content{
    display:flex;
    flex-direction:column;
    height:550px;   
    order:2; 
    width: 100%;
}
.meeting-details-container{
    display:flex;
    flex-direction: row;
    order:1;
    height:350px;
    width: 100%;
    margin:20px 40px 0px 40px;
}
.meeting-details{
    display:flex;
    flex-direction:column;
    height:100%;
    width:50%;

}
.people{
    display:flex;
    flex-direction:row;
}
.person{
    font-size: 11px;
    background-color:rgb(126, 185, 103);
    width:max-content;
    padding:4px;
    border-radius: 10px;
    margin-left:10px;
    color:white;
   
}
.description p{
    font-size: 11px;
}
.venue{
    display:flex;
    flex-direction:row;
}
.pin{
    margin-top:12px;
    color:rgb(129, 192, 129);
}
.location{
    background-color:rgb(235, 235, 235);
    padding:12px;
    border-radius: 15px;
    font-size:12px;
    margin-left: 10px;

}
.meeting-chart{
    display:flex;
    flex-direction:column;
    justify-items:space-around;
    align-content:center;
    margin-left:50px
}
.meeting-files-container{
    display:flex;
    flex-direction:row; 
    order:2; 
    width:100%;  
}
.images{
    display:flex;
    flex-direction:row;
    order: 1px;
}
.images img{
    height:170px;
    width:150px;
    border-radius: 10px;
    margin-left:10px;
    background-color:rgb(243, 243, 243);
}
.files{
    display:flex;
    flex-direction:column;
    order:2;
}
.upload-report input{
    background-color:green;
}
.download-excel{
    display:flex;
    /* background-color */
}