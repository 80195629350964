.usersetup-body{
display:flex;
flex-direction: row;
width:100vw;
height:100vh;
justify-content:center;
align-items:center;
}
.notice{
    display:flex;
    flex-direction:column;
    order:1;
    width:40vw;
    height:70vh;
    margin-right: 40px;;
}
.notice-image{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    height:40%;
    width:100%;
    order:1
}
.notice-image img{
    width:45%;
    margin:auto;
    height:90%;
}
.notice-text{
    display:flex;
    flex-direction:column;
    height:40%;
    width:100%;
    order:2
}
.notice-text h1{
    font-size:50px;
    font-weight:80;
}
.notice-text h2{
    font-size:20px;
    font-weight:300;
}
.information-card{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    order:1;
    width:30vw;
    height:70vh;
    border-radius:50px;
    box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}
.information-logo{
    display:flex;
    flex-direction:row;
    width:80%;
    height:20%;
    align-items:center;
    justify-content:center;
}
.information-logo img{
    height:30%;
    width:45px;
}
.information{
    display:flex;
    flex-direction:column;
    width: 80%;
    height:50%;
    align-items:center;
    justify-content:center;
}
.informationdiv{
    width:70%;
    height:50px;
    font-family: Poppins;
    background-color:rgb(255, 253, 253);
    outline:none;
    border-radius:50px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}
.informationdiv h4{
    font-size: 15px;
    text-align: center;
}
.contentdiv{
    margin-top:20px;
    width:70%;
    position: relative;
    height:40%;
    overflow: scroll;
    border-radius: 30px;
    padding:20px;
    box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}
.submit{
    display:flex;
    flex-direction:row;
    height:20%;
    width:80%;
    justify-content:center;
    align-items:center;
}
.submit button{
    width:50%;
    height:50%;
    border-radius: 30px;
    border:none;
    background-color: rgb(142, 209, 142);
    color:white;
    padding:15px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
}