.filterspane{
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height:50px;
    background-color: #fff;
    position: fixed;
    top:40px;
    z-index:1;
}
.countyfilter{
    width:100px;
}
.formctrl{
    margin-right:20px !important;
}
.graphpane{
    display: flex;
    flex-direction:column;
    width:100vw;
    margin:40px;
}
.calloutcard{
    display: flex;
    flex-direction:column;
    flex-basis:27%
}
.calloutvalue{
    font-size:65px;
    color:#000;
    margin:0
}
.progressgraph{
    display: flex;
    height:100%;
    flex-basis:35%;
    margin-left:0
}
.settinggraph{
    display: flex;
    height:100%;
    flex-basis:40%;
}
.theprogress{
    height:inherit !important;
    width:100%;
}
.theprogressgraph{
    height:inherit !important;
    width:90%;
}
.secondgraphactual{
    display: flex;
    height:100%;
    flex-basis:50%;
    margin-left:0
}
.progresscontrols{
    display:flex;
    flex-direction:row;
    font-family: 'Cera Pro2';
}
.progresscontrols h5{
    margin-right:10px;
}
.pcontrol{
    color:rgb(74, 134, 74);
    cursor:pointer;
}
.pcontrol:hover{
    text-decoration:underline;
}
.firstgraph{
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: left;
    height:400px;
    width:100vw;
    order:1;
    margin-bottom: 50px;;
}
.secondgraph{
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    height:400px;
    width:100vw;
    margin-bottom: 50px;;
    order:2;
}
.thirdgraph{
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    height:700px;
    width:100vw;
    margin-bottom: 50px;;
    order:3;
}
.nograph{
    display: flex;
    flex-direction:row;
    width:inherit;
    height:inherit;
    align-items: center;
    justify-content: center;
}
.nograph img{
    height:inherit;
    width:inherit;
    z-index: 10;
}