.feed-data-container{
    display:flex;
    flex-direction:column;
    margin:40px 50px ;
    width:100vw
}
.feed-data-container-header{
    display:flex;
    flex-direction:row;
    order:1;
    width:100%;
    height:150px;
    position:fixed;
    z-index:3;
    background-color:#fff;
    border-bottom: 1px solid green;
}
.feed-data-container-header-col1{
    display:flex;
    flex-direction:column;
    width:40%;
    order:1;
}
.feed-data-container-header-col1 h3{
    color:black;
}
.feed-data-container-header-row2 h5{
    font-family: 'Cera Pro2';
}
.feed-data-container-header-col2{
    display:flex;
    flex-direction:row;
    width:60%;
    order:2;
    justify-content:center;
    align-items:center;
}
.feed-data-container-header-save{
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    color:white;
    background-color:rgb(46, 136, 46);
    width:150px;
    height:40px;
    border-radius:10px;
    cursor:pointer;
    margin-left:80px;
}
.feed-data-container-header-save:hover{
    background-color:rgb(47, 90, 156)
}
.feed-data-container-header-save p{
    color:white;
    font-size:13px;
    font-family: 'Cera Pro2';
}
.feed-data-container-body{
    display:flex;
    flex-direction:column;
    order:2;
    margin-top:150px;
}
.kpi{
    display:flex;
    flex-direction:row;
    width:100%;
    height:80px;
    z-index:2;
    margin-top:20px;
    margin-bottom:0px;
    overflow:scroll;
}
.kpi-title{
    color:rgb(33, 107, 33);
    margin-bottom:0;
    margin-top:30px
}
.kpi-description{
    display:flex;
    flex-direction:column;
    width:40%;
    order:1;
    align-items:left;
    justify-content:center;
    margin-right:20px;
}
.kpi-description p{
    color:black;
    font-size:15px;
}
.kpi-data-inputs{
    display:flex;
    flex-direction:row;
    width:60%;
    order:2;
    align-items:center;
    justify-content:left;
}
.single-number{
    margin-right:30px
}