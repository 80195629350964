body {
	overflow: scroll;
	overflow-x: hidden;
}
.home-body {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	background-image: url("../images/head.png");
	background-repeat: no-repeat;
	background-size: cover;
}
.heading {
	display: flex;
	flex-direction: column;
	width: 30vw !important;
	align-items: center;
	justify-content: flex-start;
	order: 1;
	margin-left: 15vw;

}
img {
	order: 2;
	cursor: pointer;
}
.homeimage {
	height: 100vh !important;
	width: 100vw;
}
.header {
	width: 80vw;
	height: 600px !important;
	background-image: linear-gradient(
		to right,
		rgb(94, 171, 91),
		rgb(195, 223, 203)
	);
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: -180px;
	margin-bottom: 30px;
}
.header h1 {
	color: #fff;
	font-size: 25px;
	margin-bottom: 0;
}
.header p {
	color: #fff;
	font-size: 20px;
}
.graph-div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 90vw;
	margin-top: 100px;
}
.graph {
	display: flex;
	order: 2;
	height: 300px;
	width: 60%;
	border-radius: 30px;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}
.graph-details {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 37%;
	order: 1;
}
.graph-details p {
	font-size: 20px;
	color: #566171;
	font-weight: 400 !important;
}
.image-background {
	width: 100vw;
	height: 80vh;
	background-color: rgb(9, 9, 9);
	/* background-image:url('../images/header.jpg'); */
	background-size: 100vw 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.image-background h1 {
	color: #fff;
	font-size: 70px;
	margin-left: 20vw;
	margin-right: 20vw;
	text-align: center;
}
.heading h1 {
	font-size: 40px;
	font-weight: 700;
	text-align: left;
	margin-bottom:0;
	color: rgb(255, 255, 255);
}
.heading h4 {
	text-align: left;
	font-family: 'Cera Pro';
	color: rgb(255, 255, 255);
}
.fmvisualize {
	color: #b3f0d7;
}
.get-started-button {
	display: flex;
	flex-direction: row;
	order: 2;
	align-items: center;
	justify-content: center;
}
.accountsetup{
	color:white;
}
.microsoft{
	margin-top:5px;
}
.signin{
	height:50px;
	width:300px;
	background:white;
	border-radius:0;
	display: flex;
	flex-direction:row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
  }

.get-started-button button {
	background-color: #84a658;
	width: 150px;
	height: 50px;
	outline: none;
	border: none;
	font-family: Poppins;
	color: white;
	border-radius: 30px;
	cursor: pointer;
	transition: transform 0.2s;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}
.get-started-button button:hover {
	transform: scale(1.1);
}
.logo {
	display: flex;
	flex-direction: row;
	width: 50vw;
	order: 3;
	/* height:20vh; */
	/* margin-bottom: 20px; */
	justify-content: center;
}
.logo p {
	text-align: center;
}
