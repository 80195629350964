@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700&display=swap");

:root {
	--background-color: #f8f8f8;
	--text-color1: #696161;
	--text-color2: #ffffff;
}

@font-face {
	font-family: "Cera Pro2";
	src: local("Cera Pro2"), url(./fonts/Cera\ Pro\ Medium.otf);
}

@font-face {
	font-family: "Cera Pro1";
	src: local("Cera Pro1"), url(./fonts/Cera\ Pro\ Light.otf);
}

::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(216, 216, 216);
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

body {
	margin: 0;
	font-family: Cera Pro Light, -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 90vh;
	/* padding: 10px; */
}

h1,
h3,
h4,
h5,
p {
	color: var(--text-color1);
}

p {
	font-family: Cera Pro1;
	color: #566171;
	font-weight: 400;
}

h1,
h2,
h3,
h4 {
	font-family: Cera Pro2;
	font-weight: 800;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.first-element {
	margin-top: 70px !important;
}

.mobile-content {
	display: none;
}

.ver-indicator-verification {
	margin: 1em 1em 0 1em;
	display: flex;
	flex-direction: row;
	width: max-content;
	height: 6vh;
	background-color: #32bc5932;
	border: 1px solid #32bc59;
	/* background-color: #d8aed9; */
	padding: 0 1em;
	border-radius: 50px;
	align-items: center;
	justify-content: right;
	cursor: pointer;
}

.ver-indicator {
	transition: transform 0.2s;
}

.ver-indicator-link {
	height: 20px !important;
}

.ver-indicator:hover {
	transform: scale(1.03);
}

.ver-indicator-pending {
	margin: 1em 1em 0 1em;
	display: flex;
	flex-direction: row;
	width: max-content;
	height: 6vh;
	background-color: #e8913956;
	border: 1px solid #e89139;
	/* background-color: #aed9d1; */
	padding: 0 1em;
	border-radius: 50px;
	align-items: center;
	justify-content: right;
	cursor: pointer;
}

.ver-indicator-action {
	margin: 1em 1em 0 1em;
	display: flex;
	flex-direction: row;
	width: max-content;
	height: 6vh;
	background-color: #e3413b4a;
	border: 1px solid #e3413b;
	/* background-color: #aebdd9; */
	padding: 0 1em;
	border-radius: 50px;
	align-items: center;
	justify-content: right;
	cursor: pointer;
}

.ver-indicator-approved {
	margin: 1em 1em 0 1em;
	display: flex;
	flex-direction: row;
	width: max-content;
	height: 6vh;
	/* background-color: #8bcb67; */
	background-color: #85858563;
	border: 1px solid #858585;
	padding: 0 1em;
	border-radius: 50px;
	align-items: center;
	justify-content: right;
	cursor: pointer;
}

.ver-indicator-rejected {
	margin: 1em 1em 0 1em;
	display: flex;
	flex-direction: row;
	width: max-content;
	height: 6vh;
	background-color: #85858563;
	border: 1px solid #858585;
	/* background-color: #c7874f; */
	padding: 0 1em;
	border-radius: 50px;
	align-items: center;
	justify-content: right;
	cursor: pointer;
}

.ver-indicator-text {
	padding: 10px;
	order: 1;
	color: rgb(67, 67, 67);
	font-size: 11px;
}

.ver-indicator-number {
	order: 2;
	display: flex;
	flex-direction: row;
	background-color: #e4e3e3;
	width: 3vw;
	height: 5vh;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	/* margin-right:0; */
}

.ver-indicator-number-actual {
	align-self: center;
	justify-self: center;
	font-size: 11px;
}

a {
	text-decoration: none;
}

.container {
	margin: 1em 3em;
}

.ver-numbers {
	display: flex;
	flex-direction: row;
	margin-top: 0;
	height: 15vh;
}

.current-verification-page {
	order: 1;
	justify-content: left;
	flex-basis: 60%;
}

.other-verification-pages {
	order: 2;
	justify-content: right;
	display: flex;
	flex-direction: row;
	flex-basis: 40%;
}

.verification-container {
	display: flex;
	flex-direction: row;
	height: 65vh;
	margin-top: 0;
}

.v-container-left {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: inherit;
	order: 2;
	width: 60%;
}

.verification-image {
	order: 1;
	height: inherit;
	width: 40%;
	position: relative;
}

.verification-box {
	display: flex;
	flex-direction: column;
	order: 1;
	width: 100%;
	background-color: var(--background-color);
	border-radius: 20px;
	padding: 5px;
	height: 80%;
}

.sim-image {
	width: 14vw;
	height: 300px;
	background: linear-gradient(120deg,
			#e5e5e5 30%,
			#f2f2f2 38%,
			#f2f2f2 40%,
			#e5e5e5 48%);
	background-size: 200% 100%;
	background-position: 100% 0;
	animation: load 2s infinite;
	border-radius: 10px;
	order: 2;
	margin-right: 5px;
	position: relative;
}

.sim-image img {
	width: 14vw;
	height: 100%;
	border-radius: 10px;
	order: 2;
	margin-right: 5px;
}

.next-sim-image {
	background-color: rgba(227, 227, 227, 0.59);
	border-radius: 50%;
	height: 20px;
	width: 20px;
	position: absolute;
	right: 5px;
	top: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	justify-content: center;
}

.prev-sim-image {
	background-color: rgba(227, 227, 227, 0.59);
	border-radius: 50%;
	height: 20px;
	width: 20px;
	position: absolute;
	left: 5px;
	top: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.next-sim-icon {
	color: white;
}

.similar-image-container {
	order: 2;
	height: 30%;
	display: flex;
	flex-direction: column;
	justify-self: flex-start;
	align-self: flex-start;
	align-content: flex-start;
	margin-top: 100px !important;
}

.similar-image-text {
	order: 1;
	display: flex;
	height: 20%;
}

.similar-image-text h3 {
	font-size: 12px;
}

.similar-image-images {
	display: flex;
	flex-direction: row;
	height: 80%;
	order: 2;
}

.similar-image-images img {
	height: 100%;
	width: 100px;
	margin-right: 15px;
	border-radius: 15px;
	background-color: rgb(232, 232, 232);
}

.similar-skel {
	height: 130px;
	width: 100px;
	margin-right: 15px;
	border-radius: 15px;
	margin-top: 10px;
	background: linear-gradient(120deg,
			#e5e5e5 30%,
			#f2f2f2 38%,
			#f2f2f2 40%,
			#e5e5e5 48%);
	background-size: 200% 100%;
	background-position: 100% 0;
	animation: load 2s infinite;
}

@keyframes load {
	100% {
		background-position: -100% 0;
	}
}

.no-similar {
	height: 150px;
	width: 400px;
	background-color: rgba(132, 186, 132, 0.487);
	border: 1px solid rgb(65, 139, 65);
	border-radius: 15px;
	margin-top: 30px;
}

.no-similar p {
	color: rgb(19, 91, 19);
	padding: 5px 20px 5px 20px;
}

.verification-image-preview {
	display: flex;
	flex-direction: row;
	justify-content: center;
	order: 1;
	height: inherit;
	width: 100%;
}

.verification-image-preview img {
	height: 70vh;
	width: 70%;
	border-radius: 50px;
}

.verification-image img {
	/* order:1; */
	height: inherit;
	width: 80%;
	border-radius: 10px;
}

.loading-images {
	height: inherit;
	width: 28vw;
	border-radius: 30px;
	background: linear-gradient(120deg,
			#e5e5e5 30%,
			#f2f2f2 38%,
			#f2f2f2 40%,
			#e5e5e5 48%);
	background-size: 200% 100%;
	background-position: 100% 0;
	animation: load 2s infinite;
}

.image1 {
	display: block;
}

.image-indicator1 {
	position: absolute;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #9ae0bf;
	bottom: 2em;
	right: 10em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.image-indicator1 h3 {
	color: #ffffff;
}

.image-indicator2 {
	position: absolute;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #549f9b;
	bottom: 2em;
	right: 9em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.image-indicator2 h3 {
	color: #ffffff;
}

.image-indicator3 {
	position: absolute;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #486880;
	bottom: 2em;
	right: 8em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.image-indicator3 h3 {
	color: #ffffff;
}

.marketer-details-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 15%;
	justify-items: center;
	align-items: center;
	margin: 20px;
}

.marketer-details {
	display: flex;
	flex-direction: row;
	order: 1;
	width: 50%;
	/* height: 10%; */
	margin-top: 0;
}

.skip-system {
	display: flex;
	/* flex-direction: row; */
	order: 2;
	width: 50%;
	/* height: 15%; */
	/* margin-bottom: 50px; */
	align-items: flex-end;
	justify-content: flex-end;
	margin-right: 5em;
}

.skip-container {
	height: 60px;
	width: 60px;
	background-color: white;
	border-radius: 50%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	margin-right: 20px;
	font-size: 11px;
	transition: transform 0.2s;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}

.skip-container:hover {
	transform: scale(1.1);
}

.skip-button {
	margin-bottom: 10px;
}

.system-details {
	order: 2;
	background-color: #ffffff;
	border-radius: 20px;
	padding: 30px;
	margin: 0 40px;
	height: 50%;
}

.sysdetails-columns {
	display: flex;
	flex-direction: row;
}

.column1 {
	display: flex;
	flex-direction: column;
	flex-basis: 50%;
	order: 1;
}

.loading-image {
	position: absolute;
	left: 40%;
	top: 40%;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
}

.loading-image img {
	justify-content: center;
	align-items: center;
	max-width: 150px;
	max-height: 150px;
}

.filter-container {
	display: flex;
	flex-direction: column;
}

.filter-container .close-button {
	margin-left: 20px;
}

.filterImage {
	position: absolute;
	left: 30%;
	top: 30%;
	justify-content: center;
	align-items: center;
	max-width: 250px;
	max-height: 250px;
}

.preview-images {
	background-color: #549f9b;
	color: white;
	font-family: Poppins;
	font-size: 11px;
	outline: none;
	border: none;
	padding: 5px;
	border-radius: 25px;
}

.preview-images:hover {
	background-color: #417d7a;
}

.column2 {
	display: flex;
	flex-direction: column;
	flex-basis: 50%;
	order: 2;
}

.column2action {
	margin-top: 20px;
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	flex-basis: 50%;
	order: 2;
}

.column2action h3 {
	font-size: 11px;
	margin-bottom: 8px !important;
}

.map-button {
	display: flex;
	align-items: flex-end;
	margin-top: 3em;
}

.disable {
	height: 52px;
	width: 52px;
	border-color: pink;
	background-color: rgb(226, 172, 115);
	border-radius: 50%;
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 1000px;
	margin-top: 40px;
}

.newlyconst {
	height: 32px;
	width: max-content;
	/* border-color: pink;
	background-color: rgb(61, 139, 100); */
	border-radius: 50%;
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 30px;
	margin-top: 40px;
	background-color: rgba(61, 133, 61, 0.524);
	border: 1px solid rgb(61, 133, 61);
	border-radius: 10px;
	padding-right: 10px;
	padding-left: 10px;

}

.rehabilitated {
	height: 32px;
	width: max-content;
	/* border-color: pink;
	background-color: rgb(61, 139, 100); */
	border-radius: 50%;
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 30px;
	margin-top: 40px;
	background-color: rgba(133, 88, 61, 0.524);
	border: 1px solid rgb(133, 90, 61);
	border-radius: 10px;
	padding-right: 10px;
	padding-left: 10px;

}

.disabled {
	color: rgb(255, 255, 255);
}

.newlyconsth4 {
	color: #ffffff !important;
}

.previous-toilet {
	height: 60px;
	width: 60px;
	position: absolute;
	background-color: white;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	margin-left: -120px;
	font-size: 11px;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}

.next-toilet {
	height: 60px;
	width: 60px;
	position: absolute;
	background-color: white;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	margin-left: 650px;
	font-size: 11px;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}

.system-details h2 {
	margin: 0;
	color: #3f405f;
	font-size: 14px !important;
}

.system-details h3 {
	margin: 0;
	font-weight: 300;
	font-size: 13px;
}

.approval-buttons {
	order: 3;
	display: flex;
	flex-direction: row;
	height: 25%;
	margin: 20px auto;
}

.approve-button {
	background-color: #7fce72;
	border-radius: 30px;
	width: max-content;
	height: 40px;
	border: none;
	color: white;
	font-family: Poppins;
	cursor: pointer;
	font-size: 11px;
}

.approve-button-waiting {
	background-color: #d1d1d1;
	border-radius: 30px;
	width: max-content;
	height: 40px;
	border: none;
	color: white;
	font-family: Poppins;
	cursor: pointer;
	font-size: 11px;
}

.pend-button-waiting {
	background-color: #d1d1d1;
	border-radius: 30px;
	width: max-content;
	height: 40px;
	border: none;
	color: white;
	font-family: Poppins;
	cursor: pointer;
	font-size: 11px;
	margin-left: 30px;
}

.reject-button-waiting {
	background-color: #d1d1d1;
	border-radius: 30px;
	width: max-content;
	height: 40px;
	border: none;
	color: white;
	font-family: Poppins;
	cursor: pointer;
	font-size: 11px;
	margin-left: 30px;
}

.approve-button:hover {
	background-color: #6fb764;
}

.pend-button {
	background-color: #6d9ead;
	border-radius: 30px;
	width: max-content;
	height: 40px;
	border: none;
	color: white;
	font-family: Poppins;
	cursor: pointer;
	margin-left: 30px !important;
	font-size: 11px;
}

.pend-button:hover {
	background-color: #5f8895;
}

.reject-button {
	background-color: #ebb564;
	border-radius: 30px;
	width: max-content;
	height: 40px;
	border: none;
	color: white;
	font-family: Poppins;
	cursor: pointer;
	margin-left: 30px !important;
	font-size: 11px;
}

.next-button {
	background-color: #515151;
	border: none;
	color: rgb(244, 244, 244);
	font-family: Poppins;
	cursor: pointer;
	margin-left: 30px;
	font-size: 11px;
	margin-left: 50px !important;

}

.prev-button {
	background-color: #515151;
	border: none;
	color: rgb(244, 244, 244);
	font-family: Poppins;
	cursor: pointer;
	margin-right: 30px;
	font-size: 11px;
	margin-right: 50px !important;
}

.reject-button:hover {
	background-color: #cc9d55;
}

.update-button {
	background-color: #64d2eb;
	border-radius: 30px;
	width: max-content;
	height: 40px;
	border: none;
	color: white;
	font-family: Poppins;
	cursor: pointer;
	margin-left: 30px;
	font-size: 11px;
}

.update-button:hover {
	background-color: #4eb7ce;
}

.verification-marketer {
	margin: 3em 2em;
	display: flex;
	flex-direction: row;
	width: max-content;
	height: 8vh;
	background-color: #ffffff;
	padding: 0 1em;
	border-radius: 50px;
	align-items: center;
	cursor: pointer;
	transition: transform 0.2s;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}

.verification-marketer:hover {
	transform: scale(1.1);
}

.danger-indicator {
	height: 20px;
	width: 20px;
	position: absolute;
	margin-right: -60px;
	margin-top: -30px;
	border-radius: 50%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	background-color: rgb(219, 134, 48);
}

.danger-indicator-icon {
	font-size: 11px;
	color: white;
	margin-bottom: 2px;
}

.san-indicator {
	height: 20px;
	width: 20px;
	position: absolute;
	margin-right: -60px;
	margin-top: -30px;
	border-radius: 50%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	background-color: rgb(83, 173, 91);
}

.san-indicator-abs {
	height: 20px;
	width: 20px;
	position: absolute;
	margin-right: -60px;
	margin-top: -30px;
	border-radius: 50%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	background-color: rgb(196, 127, 74);
}

.san-indicator-icon {
	font-size: 10px;
	color: white;
	margin-bottom: 2px;
}

.verification-marketer-icon-holder {
	order: 1;
	display: flex;
	flex-direction: row;
	background-color: #e4e3e3;
	width: 3.8vw;
	height: 7vh;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	margin-left: -10px;
}

.verification-marketer-details {
	order: 2;
	margin-left: 10px;
}

.verification-marketer-details h4 {
	margin-bottom: 0;
	font-size: 13px;
}

.verification-marketer-details h5 {
	margin-top: 0;
	font-weight: 400;
	font-size: 11px;
}

.side-menu {
	background: #ffffff;
	height: 100vh;
	width: 50vw;
	z-index: 10;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	visibility: hidden;
	transition: all 1s ease;
	transform: translateX(0);
	border-radius: 30px 0 0 30px;
	/* border-left:1px solid rgb(78, 74, 74); */
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}

.reveal-menu {
	background: #ffffff;
	height: 60vh;
	width: 100vw;
	z-index: 9;
	/* opacity: 0; */
	position: absolute;
	bottom: 0;
	/* visibility: hidden; */
	transition: all 0.7s ease;
	transform: translateX(0);
	display: flex;
	flex-direction: column;
	border-radius: 30px 30px 0 0;
	/* border-left:1px solid rgb(78, 74, 74); */
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}

.reveal-close {
	display: flex;
	flex-direction: row;
	margin: 30px;
	order: 1;
}

.reveal-edit-container {
	display: flex;
	flex-direction: row;
	order: 2;
	margin: 30px;
}

.reveal-container-1 {
	display: flex;
	flex-direction: column;
	order: 1;
	width: 25vw;
}

.reveal-edit-box {
	height: 300px;
	width: 23vw;
	background-image: linear-gradient(to right,
			rgb(94, 171, 91),
			rgba(131, 196, 149, 0.685));
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: left;
}

.reveal-finance-box {
	height: 300px;
	width: 23vw;
	background-image: linear-gradient(to right,
			rgb(171, 130, 91),
			rgba(196, 159, 131, 0.685));
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: left;
}

.ksh {
	color: #6fb764;
}

.total-spent {
	color: #7f7f7f;
	margin-bottom: 0;
	margin-top: 0;
	font-size: 17px;
	text-align: center !important;
}

.amount-div {
	width: 300px !important;
	height: 65px !important;
	display: flex !important;
	background-color: #fff !important;
	flex-direction: column !important;
}

.reveal-container-1 h3 {
	font-size: 28px;
	color: #fff;
	padding-left: 10px;
	margin-bottom: 0;
}

.reveal-container-1 p {
	font-size: 13px;
	color: #fff;
	padding-left: 10px;
	padding-right: 20px;
}

.reveal-edit-icon {
	color: #fff;
	font-size: 40px;
	padding-left: 10px;
}

.reveal-container-1 img {
	width: 250px;
	height: 250px;
}

.reveal-container-2 {
	display: flex;
	flex-direction: column;
	order: 2;
	width: 30vw;
	height: 35vh;

	overflow: scroll !important;
}

.reveal-container-2 h5 {
	margin-left: 30px;
}

.reveal-container-3 h5 {
	margin-left: 30px;
}

.reveal-container-3 {
	display: flex;
	flex-direction: column;
	overflow: scroll;
	order: 3;
	width: 30vw;
	height: 35vh;
}

.reveal-container-4 {
	display: flex;
	flex-direction: column;
	order: 4;
	align-items: center;
	justify-content: center;
}

.financing-edit-items {
	background: none !important;
}

.finance-chart {
	background: none !important;
}

.reveal-warning {
	width: 150px;
	height: 30vh;
	background-color: rgba(244, 95, 95, 0.422);
	border: 1px solid rgb(255, 0, 0);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.reveal-warning p {
	font-size: 11px;
	color: brown;
	padding: 5px;
	text-align: center;
}

.reveal-warning-icon {
	color: brown;
	font-size: 50px;
}

.reveal-container-2 div {}

.financing-graph {
	height: 250px !important;
	width: 250px !important;
	background: none !important;
	margin-top: 0 !important;
}

.reveal-container-2 input {
	margin-bottom: 10px;
	outline: none;
	border: none;
	padding: 0 0 0 20px;
	font-family: Cera Pro1;
	text-align: left;
	width: 230px
}

.reveal-container-3 input {
	margin-bottom: 10px;
	outline: none;
	border: none;
	padding: 0 0 0 20px;
	font-family: Cera Pro1;
	text-align: left;
	width: 230px
}

.reveal-container-3 div {}

.confirm-cost {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 0;
	margin-bottom: 0;
	/* height:5px !important; */
}

.confirm-cost input {
	height: 15px !important;
	width: 15px;
}

.confirm-cost p {
	margin-right: 10px;
}

.reveal-update {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: flex-end;
	justify-content: center;
	background-color: #6fb764 !important;
	height: 50px;
	width: max-content;
	border-radius: 20px;
	margin: 20px;
	cursor: pointer;
}

.reveal-update h1 {
	color: white;
	font-size: 13px;
	padding: 0 10px 0 10px !important;
}

.sysedit {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.editsave {
	color: #33964d;
	cursor: pointer;
}

.downloads-header {
	background-image: linear-gradient(to right,
			rgb(73, 141, 73),
			rgba(147, 253, 26, 0.1));
	height: 100px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 90%;
	border-radius: 30px;
	margin-top: 30px;
	margin-bottom: 30px;
	margin-right: 30px;
	margin-left: 30px;
	order: 1;
}

.downloads-header h1 {
	padding-left: 50px;
	font-size: 20px;
	color: white;
}

.systypediv {
	border: none;
	text-align: left;
	padding: 10px;
	height: 30px;
	text-decoration: none;
	margin: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: left;
	font-size: 13px;
	width: 270px !important;
}

.systypediv-skel {
	border: none;
	border-radius: 30px;
	text-align: left;
	padding: 10px;
	height: 30px;
	text-decoration: none;
	margin: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: left;
	font-size: 13px;
	width: 270px !important;
	background: linear-gradient(120deg,
			#e5e5e5 30%,
			#f2f2f2 38%,
			#f2f2f2 40%,
			#e5e5e5 48%);
	background-size: 200% 100%;
	background-position: 100% 0;
	animation: load 2s infinite;
}

.systypediv p {
	padding-left: 10px;
	padding-right: 10px;
}

.systypedivspan {
	color: #4c7a30;
}

.edit-toilet-type {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	background-color: #fff;
	border-radius: 30px;
	width: 270px;
	height: max-content;
	overflow-x: auto;
	overflow-y: scroll;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}

.edit-toilet-type p {
	padding: 0 10px 0 30px;
	margin-bottom: 0;
}

.edit-toilet-type p:hover {
	color: green;
	text-decoration: underline;
	cursor: pointer;
}

.active {
	opacity: 1;
	visibility: visible;
	transition: all 1s ease;
	transition-duration: 2s;
	transform: 2s;
	transform: translateX(100);
}

.close-button {
	display: flex;
	flex-direction: row;
	align-content: center;
	height: 30px;
	justify-content: space-between;
	flex-basis: 70px;
	margin-top: 20px;
}

.the-close {
	height: 60px;
	width: 60px;
	background-color: white;
	border-radius: 50%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	margin-right: 20px;
	font-size: 11px;
	transition: transform 0.2s;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}

.close-button h3 {
	margin-right: 2em;
}

.side-menu-items {
	display: flex;
	flex-direction: column;
	padding: 20px;
}

.search-bar-inp {
	height: 50px;
	width: 90%;
	background-color: #eceef1;
	border-radius: 25px;
	border: 0px;
	font-family: Poppins;
	font-size: 12px;
	padding-left: 30px;
}

.search-bar-inp:focus {
	outline: none;
}

.action-input {
	height: 25px;
	/* width: 90%; */
	background-color: #eceef1;
	border-radius: 25px;
	border: 0px;
	font-family: Poppins;
	font-size: 12px;
	padding-left: 30px;
}

.action-input {
	outline: none;
}

.single-system {
	display: flex;
	flex-direction: row;
	height: 130px;
	width: 90%;
	margin-top: 30px;
	background-color: #fafafa;
	border-radius: 15px 0 0 15px;
	border-left: 10px solid rgb(80, 145, 142);
	padding: 0px 0 0 10px;
	transition: transform 0.2s;
	cursor: pointer;
}

.single-system-skel {
	display: flex;
	flex-direction: row;
	height: 130px;
	width: 90%;
	margin-top: 30px;
	background-color: #ffffff;
	border-radius: 15px 0 0 15px;
	padding: 0px 0 0 10px;
	transition: transform 0.2s;
	cursor: pointer;
}

.single-system:hover {
	box-shadow: -3px 4px 9px 3px rgba(158, 158, 158, 0.35);
	-webkit-box-shadow: -3px 4px 9px 3px rgba(158, 158, 158, 0.35);
	-moz-box-shadow: -3px 4px 9px 3px rgba(158, 158, 158, 0.35);
	transform: scale(1.01);
}

.first-col {
	order: 1;
	display: flex;
	flex-direction: column;
	justify-self: flex-start;
	flex-basis: 400px;
	margin: 10px;
}

.first-col-skel {
	order: 1;
	display: flex;
	position: relative;
	flex-direction: column;
	justify-self: flex-start;
	flex-basis: 80%;
	border-radius: 10px 0 0 10px;
	margin-right: 10px;
	background: linear-gradient(120deg,
			#e5e5e5 30%,
			#f2f2f2 38%,
			#f2f2f2 40%,
			#e5e5e5 48%);
	background-size: 200% 100%;
	background-position: 100% 0;
	animation: load 2s infinite;
}

.first-col h4 {
	font-weight: 300;
	font-size: 12px;
	margin: 0;
}

.second-col {
	order: 2;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	/* padding: 5px; */
	flex-basis: 40%;
}

.second-col-skel {
	order: 2;
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	/* padding: 5px; */
	flex-basis: 20%;
	background: linear-gradient(120deg,
			#e5e5e5 30%,
			#f2f2f2 38%,
			#f2f2f2 40%,
			#e5e5e5 48%);
	background-size: 200% 100%;
	background-position: 100% 0;
	animation: load 2s infinite;
}

.second-col img {
	height: 100%;
	width: 50%;
}

.results {
	height: 70vh;
	overflow-y: scroll;
	margin-top: 30px;
}

.primary-button {
	background-color: #8bcb67;
	color: #ffffff;
	width: 150px;
	padding: 10px;
	height: 40px;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.primary-button h3 {
	color: white;
}

.primary-button:hover {
	background-color: #6da34e;
}

.map-div {
	height: 100vh;
	background-color: rgb(53, 53, 53);
}

.map-overlay {
	height: 50vh;
	width: 30vw;
	background-color: #fff;
	position: absolute;
	top: 20vh;
	right: 20px;
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.map-range {
	position: absolute;
	z-index: 2;
	bottom: 100px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 50vw;
}

.map-range input {
	width: 100%;
	background-color: rgb(72, 154, 72);
}

.horizontal {
	width: 70%;
	background-color: green;
}

.overlay-instructions {
	background-color: rgba(137, 206, 137, 0.362);
	border: 1px solid green;
	width: 70%;
	border-radius: 20px;
}

.overlay-instructions p {
	padding: 20px;
	font-size: 14px;
	color: rgb(29, 53, 29);
}

.map-filters {
	display: flex;
	flex-direction: row;
	margin-top: 30px;
}

.close-button-div {
	position: absolute;
	top: 20px;
	left: 20px;
	background: #fff;
	height: 50px;
	width: 50px;
	z-index: 1;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.system-preview {
	height: 100%;
	width: 100%;
}

.sys-preview {
	display: flex;
	flex-direction: column;
	height: inherit;
	flex-basis: 90%;
}

.sys-preview-image {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: inherit;
	width: inherit;
}

.sys-img {
	width: 100%;
	height: 70%;
}

.back-arrow {
	margin-left: 40px;
	font-weight: 200;
	cursor: pointer;
	/* font-size: 25px; */
}

.marketer-perfomance {
	cursor: pointer;
	margin-bottom: 0;
}

.graphs-preview {
	width: 85%;
}

.modal {
	display: flex;
	flex-direction: column;
	position: relative;
	top: 20px;
	left: 0;
	width: 40vw;
	height: 50vh;
	justify-content: center;
	align-items: center;
	background-color: white;
	border-radius: 30px;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}

.modal-heading {
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: max-content;
}

.modal-heading h3 {
	font-size: 14px;
}

.modal-body {
	display: flex;
	flex-direction: column;
	flex-basis: 90%;
	height: 35vh;
	justify-content: flex-start;
	align-items: flex-start;
}

.modal-actions {
	display: flex;
	flex-direction: row;
	flex-basis: 90%;
	justify-content: center;
	align-items: flex-end;
	margin-bottom: 30px;
}

.confirm-button {
	width: 150px;
	height: 35px;
	background-color: rgb(131, 183, 131);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	cursor: pointer;
}

.confirm-button h5 {
	color: white;
}

.cancel-button {
	width: 150px;
	height: 35px;
	background-color: rgb(218, 186, 135);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	cursor: pointer;
	margin-right: 40px;
}

.cancel-button h5 {
	color: white;
}

.modal-button-waiting1 {
	width: 150px;
	height: 35px;
	background-color: rgb(220, 220, 220);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	cursor: pointer;
	margin-right: 40px;
}

.modal-button-waiting2 {
	width: 150px;
	height: 35px;
	background-color: rgb(220, 220, 220);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	cursor: pointer;
}

.backdrop {
	display: flex;
	flex-direction: row;
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: #03030399;
	justify-content: center;
	align-items: center;
	z-index: 3;
}

.pend-reasons {
	padding: 2px;
	border-radius: 20px;
	height: 40px;
	width: 300px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}

.p-reasons {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	height: 25vh;
	overflow: scroll;
	width: 300px;
	border-radius: 30px;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}

.reason {
	display: flex;
	align-items: center;
	font-size: 11px;
	font-weight: 200;
	margin: 5px 20px 5px 20px;
	padding: 0 20px;
	border-radius: 20px;
	cursor: pointer;
	height: 40px;
}

.reason:hover {
	background-color: rgb(245, 245, 245);
}

.menu-logo {
	margin-bottom: -3px;
}

.suspect-value {
	color: red;
	margin-left: 10px;
}

.warning-select {
	height: 15vh;
	width: 300px;
	border-left: 10px solid rgb(251, 186, 65);
	background-color: rgb(250, 235, 207);
	padding: 25px;
	margin-top: 20px;
	border-radius: 0 20px 0 20px;
}

.warning-select h3 {
	font-size: 11px;
	font-weight: 200;
}

.warning-select h2 {
	font-size: 12px;
}

.action-warning {
	height: max-content;
	width: 80%;
	border-left: 10px solid rgb(251, 186, 65);
	background-color: rgb(250, 235, 207);
	padding: 25px;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 0 20px 0 20px;
}

.action-warning h3 {
	font-size: 11px;
	font-weight: 500;
}

.other-reason {
	margin-top: 20px;
}

.other-reason input {
	width: 280px !important;
	font-size: 11px;
}

.approval-comment {
	display: flex;
	flex-direction: row;
	height: 70%;
	border-radius: 20px;
	border: 1px solid orange !important;
	width: inherit;
	margin-top: 15px;
	background-color: rgba(255, 166, 0, 0.095);
}

.approval-comment-rejected {
	display: flex;
	flex-direction: row;
	height: 70%;
	border-radius: 20px;
	border: 1px solid rgb(255, 0, 0) !important;
	width: inherit;
	margin-top: 15px;
	background-color: rgba(255, 17, 0, 0.095);
}

.approval-comment-notverified {
	display: flex;
	flex-direction: row;
	height: 70%;
	border-radius: 20px;
	border: 1px solid rgb(98, 155, 73) !important;
	width: inherit;
	margin-top: 15px;
	background-color: rgba(47, 255, 0, 0.095);
}

.approval-icon {
	display: flex;
	flex-direction: row;
	order: 1;
	align-items: center;
	justify-content: center;
}

.approval-comment-icon {
	font-size: 35px;
	color: rgb(255, 149, 0);
	margin: 15px;
}

.approval-comment-icon-reject {
	font-size: 35px;
	color: rgb(255, 0, 0);
	margin: 15px;
}

.approval-comment-icon-nv {
	font-size: 35px;
	color: rgb(70, 160, 80);
	margin: 15px;
}

.approval-comment-thecomment {
	display: flex;
	flex-direction: row;
	order: 2;
	align-items: center;
	justify-content: center;
}

.approval-comment-thecomment p {
	font-size: 11px;
	color: rgb(108, 77, 19);
}

/* Filter Styles */
.toilet-filters {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: inherit;
	margin-top: 10px;
	margin-bottom: -40px !important;
}

.filter-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: max-content;
	background-color: rgb(235, 235, 235);
	border-radius: 20px;
	margin-right: 10px;
	cursor: pointer;
}

.filter-item p {
	font-weight: 300;
	font-size: 13px;
}

.selected-filter {
	color: #33964d;
	font-family: Cera Pro2;
}

.current-filter {
	background-color: rgb(77, 149, 77) !important;
	border: none !important;
	color: white;
}

.current-filter p {
	color: white;
}

.filter {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: max-content;
	background-color: rgb(92, 187, 122);
	border-radius: 20px;
	margin-right: 30px;
}

.filter-text {
	font-size: 11px;
	padding: 15px;
}

.filter h2 {
	font-size: 20px;
	padding: 15px;
	color: #fff;
}

.drop-filter {
	display: flex;
	display: inline-block;
	position: absolute;
	flex-direction: column;
	background-color: white;
	border-radius: 10px;
	width: max-content;
	height: max-content;
	min-height: 120px;
	/* overflow:auto; */
	justify-content: center;
	align-items: left;
	margin-top: 10px;
	z-index: 2;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}

.drop-filter p {
	font-size: 12px;
	padding: 0 20px 0 20px;
	margin-bottom: 1px;
	text-align: left !important;
}

.drop-filter p:hover {
	cursor: pointer;
	color: rgb(92, 187, 122);
	text-decoration: underline;
}

.system-hygiene-container {
	margin-top: 70px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.hygiene-image {
	height: 350px;
	width: 350px;
	border-radius: 20px;
	margin-bottom: 30px;
}

.toilet-hygiene-icons {
	display: flex;
	flex-direction: row;
}

.toilet-hygiene-message {
	height: 80px;
	width: 350px;
	border: 1px solid rgb(74, 110, 74);
	background-color: rgba(117, 181, 117, 0.405);
	border-radius: 20px;
	margin-top: 20px;
}

.toilet-hygiene-message p {
	font-size: 13px;
	padding: 10px;
	color: rgb(42, 90, 42);
}

.markrole {
	margin: 0;

}

@media screen and (max-width: 768px) {
	.Main-content {
		display: none;
	}

	.mobile-content {
		display: block;
	}
}