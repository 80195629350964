.no-system{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width:100vw;
}
.nosystem-container{
    display: flex;
    flex-direction:column;
    width:50%;
    align-items: center;
    justify-content: center;
}
.nosystem-image{
    display: flex;
    order: 1
}
.nosystem-image img{
    height:200px;
    width:200px;
}
.nosystem-text{
    display: flex;
    order:2;
}
.nosystem-text h4{
    text-align: center;
    font-weight:700;
    color:#585858;
    font-size:20px;
}