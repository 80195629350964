.downloads-container {
	display: flex;
	flex-direction: column;
	align-content: center;
	height: 100%;
	flex-basis: 100vw;
	margin-left: 40px;
	padding: 40px;
}

.download-content {
	display: flex;
	flex-direction: column;
	order: 1;
	flex-basis: 100%;
	justify-content: center;
	align-self: left;
}
.download-content h1 {
	font-size: 18px;
	margin-bottom: 25px;
}
.download-content p {
	font-size: 13px;
	font-weight: 100;
	margin-top: 0;
}
.download-hr {
	border: 0.5px solid cadetblue;
	width: 100%;
}
.d-content {
	width: 80%;
}
.download-image {
	display: flex;
	order: 2;
	flex-basis: 40%;
	justify-content: center;
}
.download-options {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top:60px
}
.informationdiv-download {
	width: 250px;
	height: 50px;
	font-family: Cera Pro1;
	background-color: rgb(255, 253, 253);
	outline: none;
	margin-bottom: 30px;
	border-radius: 10px;
	display: flex;
	margin-right: 50px;
	margin-top: 20px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	cursor: pointer;
}
.informationdiv-download h4 {
	font-size: 13px;
	text-align: center;
}
.months-dialog1 {
	height: 300px;
	overflow: scroll;
	border-radius: 10px;
	padding-left: 15px;
	z-index: 10;
	background-color: white;
	width: 250px;
	margin-top: 95px !important;
	position: absolute;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}
.months-dialog2 {
	height: 300px;
	overflow: scroll;
	border-radius: 10px;
	padding-left: 15px;
	z-index: 10;
	background-color: white;
	width: 250px;
	margin-top: 95px !important;
	position: absolute;
	left: 380px;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}
.months-dialog3 {
	height: 300px;
	overflow: scroll;
	border-radius: 10px;
	padding-left: 15px;
	z-index: 10;
	background-color: white;
	width: 250px;
	margin-top: 95px !important;
	position: absolute;
	left: 640px;
	box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}
.month-div {
	cursor: pointer;
	padding: 2px;
	border-radius: 30px;
}
.month-div:hover h4 {
	color: rgb(113, 195, 117);
}
.month-div h4 {
	font-size: 14px;
	font-weight: 400;
	margin-left: 20px;
}
.download-button {
	background-color: rgb(112, 186, 112);
	height: 50px;
	width: max-content;
	outline: none;
	border: none;
	color: white;
	font-family: Cera Pro2;
	font-size: 13px;
	padding: 10px;
	border-radius: 10px;
	cursor: pointer;
	margin-top: 20px;
}
.download-button:hover {
	background-color: rgb(93, 157, 93);
}
.download-error {
	color: red;
	font-size: 15px !important;
}
.year-filter{
	width:100px !important;
	height:20px;
}